<template>
  <div>
    <!-- Filters -->
    <b-card no-body class="p-2">
      <h5 class="mb-1">Filters</h5>
      <!-- Table Filters -->
      <b-row cols="12">
        <b-col md="6">
          <b-form-input
            v-model="filters.search"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
        </b-col>
        <b-col md="3">
          <v-select
            v-model="filters.selectedCountry"
            :reduce="(country) => country.country_id"
            label="country_name"
            :options="this.$store.state.home.Countries"
            placeholder="Country"
          ></v-select>
        </b-col>

        <b-col md="3">
          <v-select
            v-model="filters.selectedCourseLevel"
            :reduce="(item) => item.course_level_id"
            label="course_level_name"
            :options="course_levels"
            placeholder="Course Level"
          ></v-select>
        </b-col>
      </b-row>
      <b-row cols="12" class="mt-2">
        <b-col md="5">
          <v-select
            v-model="filters.selectedUniversity"
            :reduce="(country) => country.country_id"
            label="country_name"
            :options="this.$store.state.home.Countries"
            placeholder="University"
          ></v-select>
        </b-col>

        <b-col md="4">
          <v-select
            v-model="filters.selectedCommissionType"
            :reduce="(country) => country.country_id"
            label="country_name"
            :options="this.$store.state.home.Countries"
            placeholder="Commission Type"
          ></v-select>
        </b-col>

        <b-col
          md="3"
          class="
            d-flex
            align-items-end
            justify-content-center
            mb-1 mb-md-0
            pr-2
          "
        >
          <button class="btn btn-primary w-100">Reset</button>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div class="d-flex justify-content-end mb-2">
        <b-button v-b-modal.add-commission-modal variant="primary"
          >Add Commission</b-button
        >
      </div>
      <b-table :fields="tableColumns" :items="commissions">
        <template v-slot:cell(university_name)="data">
          <span>{{ data.item.university_name }}</span>
        </template>
        <!-- <template v-slot:cell(commission_id)="data">
          <span>{{ data.item.unc_id }}</span>
        </template> -->
        <template v-slot:cell(sr_no)="data">
          <span>{{ data.index + 1 }}</span>
        </template>

        <template v-slot:cell(country_name)="data">
          <span>{{ data.item.country_name }}</span>
        </template>

        <template v-slot:cell(university_category)="data">
          <span>{{ data.item.unc_category }}</span>
        </template>

        <template v-slot:cell(commission_type)="data">
          <span>{{ data.item.cr_type }}</span>
        </template>
        <template v-slot:cell(commission_currency)="data">
          <span>{{ data.item.unc_currency }}</span>
        </template>

        <template v-slot:cell(commission_amount)="data">
          <span>{{ data.item.cr_amount ? data.item.cr_amount : "-" }}</span>
        </template>

        <template v-slot:cell(commission_percent)="data">
          <span>{{ data.item.cr_percent ? data.item.cr_percent : "-" }}</span>
        </template>

        <template v-slot:cell(course_level)="data">
          <span>{{ data.item.course_level_name }}</span>
        </template>
        <template v-slot:cell(student_count)="data">
          <span>{{ data.item.cr_student_count }}</span>
        </template>
        <template v-slot:cell(actions)="data">
          <feather-icon
            icon="EditIcon"
            size="18"
            class="cursor-pointer"
            @click="editCommission(data.item.unc_id)"
          />
        </template>
      </b-table>
    </b-card>

    <!-- Modal -->

    <b-modal
      id="add-commission-modal"
      ref="add-commission-modal"
      centered
      title="Add Commission"
      hide-footer
      size="lg"
    >
      <b-form>
        <b-row>
          <!-- <b-col md="6">
            <b-form-group label="Id" label-for="commission_id">
              <b-form-input
                id="commission_id"
                type="number"
                v-model="addForm.commission_id"
                placeholder="Enter Id"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group label="Country">
              <v-select
                id="country_name"
                v-model="addForm.country_id"
                :reduce="(country) => country.country_id"
                label="country_name"
                :options="this.$store.state.home.Countries"
                placeholder="Enter Country"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Category">
              <v-select
                id="university_category"
                v-model="addForm.unc_category"
                :options="['A', 'B', 'C']"
                placeholder="Enter Category"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Name">
              <v-select
                id="university_name"
                v-model="addForm.university_id"
                :reduce="(item) => item.university_id"
                label="university_name"
                :options="universities"
                placeholder="Enter University Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Commission Type">
              <v-select
                id="commission_type"
                v-model="addForm.cr_type"
                :options="cr_types"
                placeholder="Enter Commission Type"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Currency">
              <b-form-input
                id="commission_currency"
                v-model="addForm.unc_currency"
                placeholder="Enter Currency"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Commission Amount">
              <b-form-input
                id="commission_amount"
                type="number"
                v-model="addForm.cr_amount"
                placeholder="Enter Commission Amount"
                :disabled="addForm.cr_percent > 0"
                @input="addForm.cr_percent = null"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Commission percent">
              <b-form-input
                id="commission_percent"
                type="number"
                v-model="addForm.cr_percent"
                placeholder="Enter Commission percent"
                :disabled="addForm.cr_amount > 0"
                @input="addForm.cr_amount = null"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Course Level">
              <v-select
                id="course_level"
                v-model="addForm.course_level_id"
                :options="course_levels"
                label="course_level_name"
                :reduce="(item) => item.course_level_id"
                placeholder="Enter Course Level"
              />
            </b-form-group>
          </b-col>

          <b-col md="6" v-if="addForm.cr_type == 'slabwise_commission'">
            <b-form-group label="Student Count">
              <b-form-input
                id="student_count"
                type="number"
                v-model="addForm.cr_student_count"
                placeholder="Enter Student Count"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="addForm.cr_type == 'slabwise_commission'">
            <b-form-group label="Student Count">
              <b-form-input
                id="student_count"
                type="number"
                v-model="addForm.cr_student_count"
                placeholder="Enter Student Count"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="addForm.cr_type == 'slabwise_commission'">
            <b-form-group label="Student Count">
              <b-form-input
                id="student_count"
                type="number"
                v-model="addForm.cr_student_count"
                placeholder="Enter Student Count"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Is Active">
              <v-select
                id="is_active"
                v-model="addForm.is_active"
                :reduce="(option) => option.value"
                :options="[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]"
                placeholder="Is Active"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="addCommission">Add</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import AccountsServices from "@/apiServices/AccountsServices";
import HomeServices from "@/apiServices/HomeServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  name: "NewCommission",
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormSelect,
    BTable,
    BCard,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      commissions: [],
      universities: [],
      course_levels: [],
      cr_types: [
        "flat_commission",
        "market_commission",
        "slabwise_commission",
        "topup_on_market_commission",
        "bonus_commission",
      ],
      filters: {
        search: "",
        selectedCountry: null,
        selectedUniversity: null,
        selectedCommissionType: null,
        selectedCourseLevel: null,
      },
      addForm: {
        country_id: null,
        unc_category: null,
        university_id: null,
        cr_type: null,
        unc_currency: null,
        cr_amount: null,
        cr_percent: null,
        course_level_id: null,
        cr_student_count: null,
        // cr_active: null,
        is_active: "Y",
      },
      tableColumns: [
        { key: "sr_no", label: "Sr.No", sortable: false },
        { key: "country_name", label: "Country", sortable: false },
        { key: "university_category", label: "Category", sortable: false },
        { key: "university_name", label: "Name", sortable: false },
        { key: "commission_type", label: "Commission Type", sortable: false },
        { key: "commission_currency", label: "Currency ", sortable: false },
        {
          key: "commission_amount",
          label: "Commission Amount",
          sortable: false,
        },
        {
          key: "commission_percent",
          label: "Commission percent",
          sortable: false,
        },
        { key: "course_level", label: "Course Level", sortable: false },
        { key: "student_count", label: "Student Count", sortable: false },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    "addForm.country_id"(val) {
      if (val) {
        this.getUniversities();
      }
    },
    "$refs.add-commission-modal.hide"() {
      this.resetAddForm();
    },
  },
  methods: {
    async getCommssions() {
      try {
        const res = await AccountsServices.getAllNewCommissions();
        this.commissions = res.data.data;
      } catch (error) {
        console.error("Error i getting Commissions", error);
      }
    },
    async getCommissionById(id) {
      try {
        const res = await AccountsServices.getNewCommissionById(id);
        this.addForm = res.data.data;
        this.addForm.unc_id = id;
      } catch (error) {
        console.error("Error i getting Commissions", error);
      }
    },

    editCommission(id) {
      console.log("Getting Commission", id);
      this.getCommissionById(id);
      this.$bvModal.show("add-commission-modal");
    },
    async addCommission() {
      // console.log(this.addForm);
      try {
        const res = await AccountsServices.saveNewCommission(this.addForm);
        if (res.data.status) {
          this.getCommssions();
          this.$bvModal.hide("add-commission-modal");
          this.resetAddForm();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Success",
              icon: "Check",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.error("Error i getting Commissions", error);
      }
    },
    async getUniversities() {
      try {
        const res = await AccountsServices.getAllUniversities({
          country_id: this.addForm.country_id,
        });
        this.universities = res.data.data;
      } catch (error) {
        console.error("Error i getting Commissions", error);
      }
    },
    async getCourseLevels() {
      try {
        const res = await HomeServices.getCourseLevels();
        this.course_levels = res.data.data;
      } catch (error) {
        console.error("Error i getting Commissions", error);
      }
    },
    resetAddForm() {
      this.addForm = {
        country_id: null,
        unc_category: null,
        university_id: null,
        cr_type: null,
        unc_currency: null,
        cr_amount: null,
        cr_percent: null,
        course_level_id: null,
        cr_student_count: null,
        // cr_active: null,
        unc_active: "Y",
      };
    },
  },
  beforeMount() {
    this.$store.dispatch("home/onGetCountries");
    this.getCommssions();
    this.getCourseLevels();
  },
};
</script>
